<template>
  <div>
    <!-- NOT LOGGEDIN SCREEN -->
    <div v-if="!isLoggedIn">
      <h1 class="text-center mt-2">Welcome to the DOA Time</h1>
      <p class="text-center"
        >Please login to access your division's application</p
      >
    </div>
    <!-- LOGGEDIN SCREEN -->
    <div v-else>
      <v-row class="mt-4">
        <!-- TEXT IF THERE ARE MESSAGES -->
        <v-col sm="12">
          <!-- v-if="isSuperUser" -->
          <v-tooltip bottom v-if="isSuperUser">
            <template v-slot:activator="{on}">
              <v-btn @click="openSystemMessage" v-on="on" icon depressed small>
                <v-icon class="text-center pr-2" color="primary" size="30"
                  >mdi-message-plus</v-icon
                >
              </v-btn>
            </template>
            <span>Add New Message</span>
          </v-tooltip>
          <span
            v-if="systemMessage.length > 0"
            class="font-weight-medium display-1"
            >You have {{ systemMessage.length }} messages</span
          >
          <!-- TEXT IF THERE ARE NO MESSAGES -->
          <span v-else class="font-weight-medium display-1"
            >You have no System Messages</span
          >
        </v-col>
      </v-row>
      <!-- MESSAGES CARDS -->
      <v-row>
        <v-col
          v-for="message in mappedData"
          :key="message.ID"
          class="mt-4 mb-2 display-1"
          sm="4"
        >
          <v-card height="100%">
            <v-card-title class="caption">
              By {{ message.enteredBy }}, {{ message.EnteredDate | dateformat }}
              <!-- v-if="isSuperUser" -->
              <v-row align="center" justify="end" v-if="isSuperUser">
                <v-tooltip bottom>
                  <template v-slot:activator="{on}">
                    <v-btn
                      @click="editMessage(message)"
                      v-on="on"
                      icon
                      depressed
                      small
                    >
                      <v-icon class="text-center pr-2" color="primary" size="25"
                        >mdi-pencil</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Edit Message</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{on}">
                    <v-btn
                      @click="deleteMessage(message)"
                      v-on="on"
                      icon
                      depressed
                      small
                    >
                      <v-icon class="text-center pr-2" color="primary" size="25"
                        >mdi-delete</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Delete Message</span>
                </v-tooltip>
              </v-row>
            </v-card-title>
            <v-card-text>
              <span class="font-weight-bold pt-2 pr-2 d-inline-block"
                >Message:</span
              >
              <div class="message">{{ message.Message }}</div>
            </v-card-text>
            <!-- v-if="isSuperUser" -->
            <v-card-actions
              class="caption font-italic pl-4 align-baseline"
              v-if="isSuperUser"
            >
              <div v-if="message.ExpireDate != null"
                >This message will expire on
                {{ message.ExpireDate | dateformat }}</div
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <!-- MESSAGE DIALOG BOX -->
    <v-dialog
      v-model="messageDialog"
      @keydown.esc="messageDialog = false"
      width="450"
      scrollable
    >
      <v-form ref="messageForm" @submit.prevent lazy-validation v-model="valid">
        <v-card class="elevation-3">
          <v-card-title class="primary">
            <h3 class="hpHeading white--text">System Message</h3>
          </v-card-title>
          <v-card-text class="pa-4">
            <v-row>
              <v-col sm="12">
                <v-textarea
                  v-model="selectedSystemMessage.Message"
                  label="New Message"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12">
                <BaseDatePickerWithText
                  label="Expire"
                  v-model="selectedSystemMessage.ExpireDate"
                ></BaseDatePickerWithText>
              </v-col>
            </v-row>
          </v-card-text>
            <v-card-actions>
              <v-btn @click="saveMessage" :disabled="saving" color="primary"
                >Save</v-btn
              >
              <v-btn @click="closeSystemMessage" outlined color="primary"
                >Cancel</v-btn
              >
            </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <!-- CONFIRM DELETE DIALOG BOX -->
    <v-dialog persistent v-model="deleteDialog" scrollable width="700">
      <v-form ref="messageForm" @submit.prevent lazy-validation v-model="valid">
        <v-card class="elevation-3">
          <v-card-title class="primary">
            <h3 class="hpHeading white--text"
              >Are you sure you want to delete this message?</h3
            >
          </v-card-title>
          <v-card-text class="pa-4">
          </v-card-text>
            <v-card-actions>
              <v-btn @click="closeDeleteMessage" color="primary">Cancel</v-btn>
              <v-btn @click="deleteSelectedMessage" outlined color="primary"
                >Delete</v-btn
              >
            </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import {get, sync, commit, call} from 'vuex-pathify'
import {authMethods, authComputed} from '@state/helpers'
import SystemMessage from '@classes/SystemMessage'
export default {
  components: {},
  data() {
    return {
      selectedSystemMessage: new SystemMessage(),
      messageDialog: false,
      deleteDialog: false,
      valid: true,
      data: [],
      message: Array,
      saving: false,
    }
  },
  created() {
    if (this.isLoggedIn) {
      this.loadSystemMessage()
    }
  },
  computed: {
    ...authComputed,
    systemMessage: get('systemMessage/systemMessage'),
    mappedData() {
      var message = this.systemMessage.map((item) => {
        return {
          ...item,
          enteredBy: item.EnteredByNavigation
            ? item.EnteredByNavigation.FirstName +
              ' ' +
              item.EnteredByNavigation.LastName
            : 'unknown',
        }
      })
      return message
    },
  },
  methods: {
    saveSystemMessage: call('systemMessage/saveSystemMessage'),
    loadSystemMessage: call('systemMessage/loadSystemMessage'),
    deleteSystemMessage: call('systemMessage/deleteSystemMessage'),
    async validate() {
      this.$refs.messageForm.validate()
    },
    openSystemMessage() {
      this.messageDialog = !this.messageDialog
    },
    closeSystemMessage() {
      this.selectedSystemMessage = new SystemMessage()
      this.messageDialog = false
    },
    editMessage(message) {
      this.selectedSystemMessage = new SystemMessage(message)
      this.messageDialog = true
    },
    deleteMessage(message) {
      this.selectedSystemMessage = new SystemMessage(message)
      this.deleteDialog = true
    },
    closeDeleteMessage() {
      this.selectedSystemMessage = new SystemMessage()
      this.deleteDialog = false
    },
    saveMessage() {
      this.validate().then(() => {
        if (this.valid) {
          this.saving = true
          this.saveSystemMessage(this.selectedSystemMessage.root())
            .then((res) => {
              this.selectedSystemMessage = new SystemMessage()
              this.loadSystemMessage()
              this.messageDialog = false
              this.saving = false
              this.$refs.messageForm.reset()
            })
            .catch((error) => {
              this.saving = false
              console.error(error)
            })
        }
      })
    },
    deleteSelectedMessage() {
      let index = this.systemMessage.findIndex(
        (q) => q.ID == this.selectedSystemMessage.ID
      )
      this.deleteSystemMessage(this.selectedSystemMessage).then(
        (res) => {
          this.systemMessage
          this.selectedSystemMessage = new SystemMessage()
          this.deleteDialog = false
          this.$refs.messageForm.reset()
        },
        (err) => {}
      )
    },
  },
  watch: {
    isLoggedIn(nval) {
      if (nval) {
        this.loadSystemMessage()
      }
    },
  },
}
</script>
<style lang="scss" scoped>
// @import '@design';
.message {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
</style>