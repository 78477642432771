<!-- BODY LAYOUT -->
<template>
  <v-container fluid>
    <!-- <v-row>
        <v-col sm="12">
          <h1 class="display-2 font-weight-bold my-4 text-center">Welcome to the DOA Time</h1>
          <p class="subheading font-weight-regular text-center">
            some stuff and things more stuff and things
            <strong>red</strong>
          </p>
        </v-col>
      </v-row>-->
    <SystemMessage />
  </v-container>
</template>


<!-- SCRIPTS -->
<script>
import SystemMessage from '@components/systemMessage'

export default {
  components: {
    SystemMessage,
  },
  data: () => ({
    //
  }),
}
</script>

<!-- STYLES -->
<style lang="scss">
//@import '@design';
</style>

