import User from './User'
export default class SystemMessage {
  constructor(objIn = {}) {
    const defaults = {
      //ID is required
      ID: 0,
      //Message is required
      Message: '',
      //EnteredBy is required
      EnteredBy: 0,
      //EnteredDate is required
      EnteredDate: new Date().toISOString().split('T')[0],
      ExpireDate: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.ID = obj.ID
    this.Message = obj.Message
    this.EnteredBy = obj.EnteredBy
    this.EnteredDate = obj.EnteredDate
    this.ExpireDate = obj.ExpireDate

    this.EnteredByNavigation = new User(obj.EnteredByNavigation || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.EnteredByNavigation = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.EnteredByNavigation = null
    return obj
  }
}